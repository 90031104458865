import Nft2 from "assets/img/nfts/Nft2.png";
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft3 from "assets/img/nfts/Nft3.png";

import Card from "components/card";
import { MdDownload, MdFilePresent } from "react-icons/md";

const DocumentsCard = () => {
  const HistoryData = [
    {
      image: Nft1,
      title: "Prescription.pdf",
      owner: "",
      price: 0.4,
      time: "30s",
    },
    {
      image: Nft2,
      title: "X-ray.pdf",
      owner: "",
      price: 2.4,
      time: "50m",
    },
    {
      image: Nft3,
      title: "UltraSound.pdf",
      owner: "",
      price: 0.3,
      time: "20s",
    }
  ];

  return (
    <Card extra={"mt-3 !z-5 overflow-hidden"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          Documents
        </div>
        <button className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          Add files
        </button>
      </div>

      {/* History CardData */}
      {HistoryData.map((data, index) => (
        <div className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          <div className="flex items-center gap-3">
            <div className="flex h-16 w-16 items-center justify-center">
              <MdFilePresent className="text-4xl text-brand-500 dark:text-white" />
            </div>
            <div className="flex flex-col">
              <h5 className="text-base font-bold text-navy-700 dark:text-white">
                {" "}
                {data.title}
              </h5>
              <p className="mt-1 text-sm font-normal text-gray-600">
                {" "}
                {data.owner}{" "}
              </p>
            </div>
          </div>

          <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
            <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white">
              <button className="flex items-center justify-center rounded-lg p-2 text-xl text-brand-400 dark:text-white transition-opacity hover:cursor-pointer hover:opacity-80">
                <MdDownload />
              </button>
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};

export default DocumentsCard;
