import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import React, { useState, useEffect } from "react";
import AuthProvider from "context/AuthProvider";
import PageNotFound from "views/errors/pageNotFound";
import {ToastContainer} from "react-toastify";
import PatientLayout from "./layouts/patient";
import PhysicianLayout from "./layouts/physician";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {AvailableLanguages} from "./i18n/AvailableLanguages";

i18n.use(initReactI18next)
    .init({
      resources: AvailableLanguages as Record<string, { translation: Record<string, string> }>,
    })
    .then(() => console.log("i18n is initialized"));

const availableLanguagesList = Object.keys(AvailableLanguages);
let preferredLanguage = localStorage.getItem("heuplusLngPrf");

if (!preferredLanguage) {
  preferredLanguage = window.navigator.language.slice(0, 2);
}

if (availableLanguagesList.includes(preferredLanguage)) {
  localStorage.setItem("heuplusLngPrf", preferredLanguage);
} else {
  // Falling back to English
  localStorage.setItem("heuplusLngPrf", "en");
  preferredLanguage = "en";
}

i18n.changeLanguage(preferredLanguage)
    .then(() => console.log("Language is set to", preferredLanguage));

const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState<any>({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#F0F2F5",      // Lightest shade adjusted for a cooler, more neutral tone
    "--color-100": "#DADEE3",     // Light gray for subtle backgrounds
    "--color-200": "#C0C4CC",     // Light blue-gray for lighter elements
    "--color-300": "#27374D",     // Main theme color used here
    "--color-400": "#506580",     // Medium blue-gray for secondary elements
    "--color-500": "#27374D",     // Main theme color repeated for emphasis
    "--color-600": "#202D40",     // Darker shade of the main theme for depth
    "--color-700": "#17202A",     // Very dark blue-gray for strong accents
    "--color-800": "#27374D",     // Main theme color used in another layer
    "--color-900": "#0D1117",     // Almost black, for text or critical elements
  });
  const [mini, setMini] = useState(false);

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line
  }, [themeApp]);
  return (
    <div className="App">
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
      />
      <AuthProvider>{
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route
              path="admin/*"
              element={
                <AdminLayout
                    setMini={setMini}
                    mini={mini}
                    theme={themeApp}
                    setTheme={setThemeApp}
                />
              }
          />
          <Route
            path="physician/*"
            element={
              <PhysicianLayout
                setMini={setMini}
                mini={mini}
                theme={themeApp}
                setTheme={setThemeApp}
              />
            }
          />
          <Route
              path="patient/*"
              element={
                <PatientLayout
                    setMini={setMini}
                    mini={mini}
                    theme={themeApp}
                    setTheme={setThemeApp}
                />
              }
          />
          <Route path="/" element={<Navigate to="/auth/" replace />} />
          <Route
            path="*"
            element={<PageNotFound />}
          />
        </Routes>
      }</AuthProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
