import { IoHeart, IoHeartOutline } from "react-icons/io5";
import React, { useState } from "react";
import Card from "components/card";
import Rating from "views/patient/choosePhysician/components/Rating";
import { MdOutlineStar } from "react-icons/md";
import { useTranslation } from 'react-i18next';

const PhysicianCard = (props: {
  profilePhotoUrl: string;
  firstName: string;
  lastName: string;
  physicianId: string;
  qualifications: string;
  specialization: string;
  description: string;
  educationRecord: string;
  previousExperience: string;
  extra?: string;
}) => {
  const { t } = useTranslation();
  const { profilePhotoUrl, firstName, lastName, physicianId, qualifications, specialization, description, educationRecord, previousExperience, extra } = props;
  const [heart, setHeart] = useState(true);
  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
    >
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
              src={profilePhotoUrl}
              className="mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full"
              alt=""
          />
          <button
              onClick={() => setHeart(!heart)}
              className="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer"
          >
            <div
                className="flex h-full w-full items-center justify-center rounded-full text-xl hover:bg-gray-50 dark:text-navy-900">
              {heart ? (
                  <IoHeartOutline/>
              ) : (
                  <IoHeart className="text-brand-500"/>
              )}
            </div>
          </button>
        </div>

        <div className="md:mr-8">
          <Rating value="4.0" stars={4} mb="mb-1"/>
        </div>

        <div
            className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2">
            <a href={"/patient/physician-profile/"+physicianId} className="text-lg font-bold text-navy-700 dark:text-white">
              {" "}
              {`Dr. ${firstName} ${lastName}`}
            </a>
            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
              {specialization} {" "}
            </p>
          </div>

          <div className="flex items-center justify-between">
            <button
                disabled={false}
                className="mt-0.5 flex items-center gap-0.5 rounded-lg bg-lightPrimary p-0.5 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
              <MdOutlineStar/>
              <p className="text-base font-medium">{t('Popular')}</p>
            </button>
          </div>
        </div>

        <div
            className="flex flex-col items-center justify-center md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
          <div className="flex">
            <p className="mb-2 text-sm font-bold text-brand-500 dark:text-white">
              {t('Next available')}: Nov 4 at 5:30 pm
            </p>
          </div>
          <button
              onClick={() => window.location.href = "/patient/schedule-appointment/"+physicianId}
              className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
            {t('Book Appointment')}
          </button>
        </div>
      </div>
    </Card>
  );
};

export default PhysicianCard;
