import translationEN from "./en.json";
import translationTR from "./tr.json";
import translationES from "./es.json";
import translationFR from "./fr.json";
import translationRU from "./ru.json";
import translationDE from "./de.json";
import translationIT from "./it.json";

interface Language {
    name: string;
    flag: string;
    translation: Record<string, string>;
}

interface AvailableLanguagesType {
    [key: string]: Language;
}

export const AvailableLanguages: AvailableLanguagesType = {
    en: {
        name: "English",
        flag: "us",
        translation: translationEN,
    },
    tr: {
        name: "Türkçe",
        flag: "tr",
        translation: translationTR,
    },
    es: {
        name: "Español",
        flag: "es",
        translation: translationES,
    },
    fr: {
        name: "Français",
        flag: "fr",
        translation: translationFR,
    },
    ru: {
        name: "Русский",
        flag: "ru",
        translation: translationRU,
    },
    de: {
        name: "Deutsch",
        flag: "de",
        translation: translationDE,
    },
    it: {
        name: "Italiano",
        flag: "it",
        translation: translationIT,
    },
};