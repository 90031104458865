import Dropdown from "../dropdown";
import {AvailableLanguages} from "../../i18n/AvailableLanguages";
import React, {useState} from "react";
import i18n from "i18next";
// @ts-ignore
import Flag from "react-world-flags";

const LanguageSelector = () => {
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("heuplusLngPrf"));
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const languageList = Object.keys(AvailableLanguages);

    const handleLanguageChange = (code: string) => {
        localStorage.setItem("heuplusLngPrf", code);
        i18n.changeLanguage(code).then(
            () => {
                setSelectedLanguage(code);
                setIsDropdownOpen(false);
                console.log("Language is set to", code);
            }
        );
    };

    return (
        <>
        {/* Language Selector */}
        <div className="relative">
            <Dropdown
                button={
                    <div className="flex items-center space-x-2 cursor-pointer"
                         onClick={() => setIsDropdownOpen(true)}
                    >
                        <Flag
                            code={languageList.includes(selectedLanguage) ? AvailableLanguages[selectedLanguage].flag : "us"}
                            className="h-5 w-5"/>
                    </div>
                }
                children={
                    isDropdownOpen && (
                        <div className="flex flex-col p-2 bg-white dark:bg-navy-700 rounded-md shadow-md">
                            {languageList.map((lang) => (
                                <button
                                    key={lang}
                                    onClick={() => handleLanguageChange(lang)}
                                    className="flex items-center space-x-2 p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                                >
                                    <Flag code={AvailableLanguages[lang].flag} className="h-5 w-5"/>
                                    <span>{AvailableLanguages[lang].name}</span>
                                </button>
                            ))}
                        </div>
                    )
                }
                classNames="top-8 -left-[180px]"/>
        </div>
        </>
    );
}

export default LanguageSelector;