import Card from "components/card";
import Banner from "./components/Banner";
import Content from "./components/Content";
import {useAuth} from "../../../context/AuthProvider";
import {useParams} from "react-router-dom";
import React from "react";
import {
  Configuration,
  PhysicianControllerApi,
  ResultWithDataObject
} from "../../../rest-client-sdk";
import {Physician, Slot} from "./interfaces";

const ScheduleAppointment = () => {
  const auth = useAuth();

  const { physicianId } = useParams();

  const [physician, setPhysician] = React.useState({
    physicianId: "",
    description: "",
    educationRecord: "",
    previousExperience: "",
    profilePhotoUrl: null,
    firstName: "",
    lastName: "",
    specialization: "",
    qualifications: "",
  });

  const physicianControllerApi = new PhysicianControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }));

  const [appointmentSlots, setAppointmentSlots] = React.useState([]);

  const getPhysician = async () => {
    try {
      let response = await physicianControllerApi.getPhysicianDetails1(physicianId, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        }
      });

      let responseData: ResultWithDataObject = response.data;
      let physician: Physician = responseData.data as Physician;
      if (physician !== null && physician !== undefined) {
        setPhysician(physician);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const getAppointmentSlots = async () => {
    try {
      let response = await physicianControllerApi.getPhysicianAvailability(physicianId, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      let responseData: ResultWithDataObject = response.data;
      let availableSlots = responseData.data;
      if (availableSlots !== null && availableSlots !== undefined) {
        if (Array.isArray(availableSlots)) {
          setAppointmentSlots(availableSlots as Slot[]);
        } else {
          console.error("Expected content to be an array");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getPhysician().then(()=>{console.log("Physician fetched")});
    getAppointmentSlots().then(()=>{console.log("Appointment slots fetched")});
  }, []);

  return (
    <div className="mt-3 w-full rounded-[20px] font-dm md:h-[1150px] 3xl:px-52">
      <Card extra={"w-full xl:w-[95%] 3xl:w-[100%] mx-auto p-4 h-full"}>
        {/* banner */}
        <div>
          <Banner profilePhotoUrl={physician.profilePhotoUrl} physicianName={physician.firstName + " " + physician.lastName} specialization={physician.specialization} qualifications={physician.qualifications}/>

          <div className="mx-auto -mt-[80px] overflow-visible px-2 md:-mt-10 md:px-14 lg:w-[90%] 3xl:w-[100%] 3xl:px-6">
            <Content slots={appointmentSlots} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ScheduleAppointment;
