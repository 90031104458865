import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import Checkbox from "components/checkbox";
import { useAuth } from "../../../context/AuthProvider";
import React from "react";

import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/language/LanguageSelector";
import { notify, notifyError } from "../../../toast";
import Radio from "components/radio";

function SignUp() {
  const { t } = useTranslation();
  const auth = useAuth();

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const firstName = (document.getElementById("firstname") as HTMLInputElement).value;
    const lastName = (document.getElementById("lastname") as HTMLInputElement).value;
    const phone = (document.getElementById("phone") as HTMLInputElement).value;
    const dob = (document.getElementById("dob") as HTMLInputElement).value;
    const gender = "OTHER";
    const email = (document.getElementById("email") as HTMLInputElement).value;
    const password = (document.getElementById("password") as HTMLInputElement)
      .value;
    const role = (document.getElementById("patient") as HTMLInputElement).checked ? "ROLE_PATIENT" : "ROLE_PHYSICIAN";


    if (email === "" || password === "" || firstName === "" || lastName === "" || phone === "" || dob === "") {
      notify(t("Please fill all the fields"));
      return;
    }

    // check if password is strong
    if (password.length < 8) {
      notify(t("Password must be at least 8 characters long"));
      return;
    }
    if (password.search(/[a-z]/) < 0) {
      notify(t("Password must contain at least one lowercase letter"));
      return;
    }
    if (password.search(/[A-Z]/) < 0) {
      notify(t("Password must contain at least one uppercase letter"));
      return;
    }
    if (password.search(/[0-9]/) < 0) {
      notify(t("Password must contain at least one digit"));
      return;
    }

    registerRequest(email, password, firstName, lastName, phone, dob, gender, role).catch((err) => {
      console.error(err);
      notifyError(err);
    });
  }

  const registerRequest = async (email: String, password: String, firstName: String, lastName: String, phone: String, dob: String, gender: String, role: String) => {

    const bodyData = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phone,
      dateOfBirth: dob,
      gender: gender,
      role: role,
    };

    auth.registerAction(bodyData);
  };

  // @ts-ignore
  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign up section */}
          <div className="mt-[10vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <div className="flex items-center justify-between">
              <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                {t('Sign Up')}
              </h3>
              <LanguageSelector />
            </div>
            <p className="mt-[10px] ml-1 text-base text-gray-600">
              {t('Enter your email and password to sign up!')}
            </p>
            {/*
            <div className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:!bg-navy-800">
              <div className="rounded-full text-xl">
                <FcGoogle />
              </div>

              <p className="text-sm font-medium text-navy-700 dark:text-white">
                Sign Up with Google
              </p>
            </div>
            <div className="mt-6 mb-4 flex items-center gap-3">
              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
              <p className="text-base font-medium text-gray-600"> or </p>
              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            </div>
            */}
            {/* user info */}
            <div className="mb-3 flex w-full items-center justify-center gap-4">
              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label={t('First Name')}
                  placeholder="John"
                  id="firstname"
                  type="text"
                />
              </div>

              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label={t('Last Name')}
                  placeholder="Doe"
                  id="lastname"
                  type="text"
                />
              </div>
            </div>
            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label={t('Email')}
              placeholder="mail@heuplus.io"
              id="email"
              type="email"
            />
            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label={t('Password')}
              placeholder={t('Min. 8 characters')}
              id="password"
              type="password"
            />
            {/* Phone */}
            <InputField
              variant="auth"
              extra="mb-3"
              label={t('Phone')}
              placeholder="123-456-7890"
              id="phone"
              type="tel"
            />
            {/* Day of Birth */}
            <InputField
              variant="auth"
              extra="mb-3"
              label={t('Date of Birth')}
              placeholder="MM/DD/YYYY"
              id="dob"
              type="date"
            />
            <div className="flex mb-3 justify-evenly">
              <div className="flex">
                <Radio id="patient" name="role" checked={true} />
                <label
                  htmlFor="patient"
                  className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                >
                  {t('Patient')}
                </label>
              </div>
              <div className="flex">
                <Radio id="physician" name="role" />
                <label
                  htmlFor="physician"
                  className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                >
                  {t('Physician')}
                </label>
              </div>
            </div>
            {/* Gender
            <InputField
                variant="auth"
                extra="mb-3"
                label="Gender"
                placeholder="Select Gender"
                id="gender"
                type="select"
            >
              <option value="" disabled>Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </InputField>
            */}
            {/* Checkbox */}
            <div className="mt-4 flex items-center justify-between px-2">
              <div className="flex">
                <Checkbox id="checkbox" />
                <label
                  htmlFor="checkbox"
                  className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                >
                  {t('By creating an account means you agree to the Terms and Conditions, and our Privacy Policy')}
                </label>
              </div>
            </div>

            {/* button */}

            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(e)}
              className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              {t('Create my account')}
            </button>

            <div className="mt-3">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                {t('Already a member?')}
              </span>
              <a
                href="/auth/sign-in"
                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              >
                {t('Sign In')}
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SignUp;
