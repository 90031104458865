/*eslint-disable*/
import {useTranslation} from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="z-[5] mx-auto flex w-full max-w-screen-sm flex-col items-center justify-between px-[20px] pb-4 lg:mb-6 lg:max-w-[100%] lg:flex-row xl:mb-2 xl:w-[1310px] xl:pb-6">
      <p className="mb-6 text-center text-sm text-gray-600 md:text-base lg:mb-0">
        ©{new Date().getFullYear()} Heuplus. {t('All Rights Reserved')}.
      </p>
      <ul className="flex flex-wrap items-center sm:flex-nowrap">
      <li>
            <a
              target="blank"
              href="https://heuplus.io/privacy-policy.html"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
                {t('Privacy Policy')}
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://heuplus.io/terms-and-conditions.html"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
                {t('Terms and Conditions')}
            </a>
          </li>
      </ul>
    </div>
  );
}
