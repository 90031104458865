import Banner from "./components/Banner";
import ContactUs from "./components/ContactUs";
import Card from "../../../components/card";

const ProfileSetting = () => {
    return (
        <div className="mt-3 w-full rounded-[20px] font-dm md:h-[1150px] 3xl:px-52">
            <Card extra={"w-full xl:w-[95%] 3xl:w-[100%] mx-auto p-4 h-full"}>
                {/* banner */}
                <div>
                    <Banner />

                    <div className="mx-auto -mt-[80px] overflow-visible px-2 md:-mt-10 md:px-14 lg:w-[90%] 3xl:w-[100%] 3xl:px-6">
                        <ContactUs />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ProfileSetting;
