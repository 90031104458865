import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { useNavigate } from "react-router-dom";
import { AuthenticationControllerApi, ForgotPasswordRequest } from "rest-client-sdk";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../../../components/language/LanguageSelector";
import React from "react";
import {notify, notifyError} from "../../../toast";
function ForgotPassword() {

  const { t } = useTranslation();
  const api = new AuthenticationControllerApi()
  const navigate = useNavigate()

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    console.log("clicked")
    e.preventDefault();
    const email = (document.getElementById("email") as HTMLInputElement).value;

    if (email === "") {
      notify(t("Please fill all the fields"));
      return;
    }

    forgotPasswordRequest(email).then(res => {
      notify(res.data.message);
      if (res.data.statusCode !== 200) {
        return;
      }
      navigate("/auth/sign-in")

    }).catch((err) => {
      console.error(err);
      notifyError(err);
      navigate("/auth/sign-in")
    });
  }

  const forgotPasswordRequest = async (email: string) => {

    const bodyData: ForgotPasswordRequest = {
      email: email,
    };

    return api.forgotPassword(bodyData);
  }


  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-24">
            <div className="flex items-center justify-between">
              <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
                {t('Forgot Your Password?')}
              </h2>
              <LanguageSelector/>
            </div>
            <p className="mt-[10px] ml-1 mb-8 text-base text-gray-600">
              {t("No problem. Just let us know your email address and we'll email you a password reset link that will allow you to choose a new one.")}
            </p>
            {/* Email */}
            <InputField
                variant="auth"
                label={t('Email')}
                placeholder="mail@heuplus.io"
                id="email"
                type="text"
            />
            {/* button */}
            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(e)}
                    className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              {t('Email password reset link')}
            </button>
          </div>
        </div>
      }
    />
  );
}

export default ForgotPassword;
