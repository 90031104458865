// Assets
import {useTranslation} from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();

    return (
        <div
            className="mx-auto rounded-2xl bg-gradient-to-br from-brand-400 to-brand-600 px-4 pt-9 pb-[140px] font-dm md:w-[90%] md:pb-[70px] lg:h-[315px] lg:!px-[50px] lg:pt-[70px] xl:!px-[50px] 3xl:w-full flex items-center justify-center">
            {/* Header */}
            <div className="flex flex-col md:flex-row">

                <div className="mb-[24px]">
                    <h1 className="text-xl font-bold text-white md:text-3xl lg:text-[38px]">
                        {t('Contact')}
                    </h1>
                    <h5 className="mt-2 text-lg font-normal text-white lg:text-xl">
                        {t("Fill up the form below to contact us")}
                    </h5>
                </div>

            </div>
        </div>
    );
};

export default Banner;
