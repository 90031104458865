import React from "react";
import Card from "components/card";
import SearchIcon from "components/icons/SearchIcon";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import {useTranslation} from "react-i18next";

type RowObj = {
  appointmentActions: AppointmentAction;
  date: string;
  patientProfile: string;
  appointmentType: string;
  timeScheduled: string;
  status: string;
};

type AppointmentAction = {
  id: string;
  appointmentType: string
}

const AppointmentType: { [key: string]: string } = {
  "TELECONSULTATION_VIDEO": "Teleconsult (Video)",
  "CONSULTATION_IN_PERSON": "Consultation (In-person)",
  "TELECONSULTATION_AUDIO": "Teleconsult (Audio)",
  "TELECONSULTATION_CHAT": "Teleconsult (Chat)",
};

const AppointmentStatus: { [key: string]: string } = {
  "WAITING_FOR_APPROVAL": "Waiting for Approval",
  "WAITING_FOR_PAYMENT": "Waiting for Payment",
  "APPROVED": "Approved",
  "CANCELLED": "Cancelled",
  "COMPLETED": "Completed",
};

function AppointmentTable(props: { appointmentData: any }) {
  const { t } = useTranslation();
  const { appointmentData } = props;
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
      []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };
  // @ts-ignore
  const columns = [
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">{t('Date')}</p>
      ),
      cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {new Date(info.getValue()).toLocaleDateString()}
          </p>
      ),
    }),
    columnHelper.accessor("patientProfile", {
      id: "patientProfile",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            {t('Patient Profile')}
          </p>
      ),
      cell: (info: any) => (
          <div className="flex w-full items-center gap-[14px]">
            <div className="flex h-[52px] w-[52px] items-center justify-center rounded-full bg-blue-300">
              <img
                  className="h-full w-full rounded-full"
                  src={info.getValue().profilePhotoUrl}
                  alt=""
              />
            </div>
            <p className="font-medium text-navy-700 dark:text-white">
              <a href={"/physician/patient-profile/" + info.getValue().id}>
                {info.getValue().name}
              </a>
            </p>
          </div>
),
}),
  columnHelper.accessor("appointmentType", {
    id: "appointmentType",
    header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">{t('Type of Appointment')}</p>
    ),
    cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue() ? t(AppointmentType[info.getValue()]) : "-"}
          </p>
      ),
    }),
    columnHelper.accessor("timeScheduled", {
      id: "timeScheduled",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            {t('Time Scheduled')}
          </p>
      ),
      cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {new Date(info.getValue()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            {t('Status')}
          </p>
      ),
      cell: (info) => (
          <div
              className={`flex h-7 w-[200px] items-center justify-center text-sm ${
                  info.getValue() === "Completed"
                      ? "bg-green-100 dark:bg-green-50"
                      : "bg-red-100 dark:bg-red-50"
              } rounded-[10px] text-base font-bold `}
          >
            <div
                className={`${
                    info.getValue() === "Completed"
                        ? "text-green-500 "
                        : "text-red-500"
                } uppercase `}
            >
              {info.getValue() ? t(AppointmentStatus[info.getValue()]) : "-"}
            </div>
          </div>
      ),
    }),
    columnHelper.accessor("appointmentActions", {
      id: "appointmentActions",
      header: () => <></>,
      cell: (info) => (
          <div className="flex items-center space-x-2">
            { info.getValue().appointmentType === "CONSULTATION_IN_PERSON" &&
                <button className="px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                        onClick={() => {
                          console.log("View appointment:", info.getValue());
                        }}
                >
                  {t('View')}
                </button>
            }
            { info.getValue().appointmentType === "TELECONSULTATION_CHAT" &&
                <button className="px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                        onClick={() => {
                          console.log("View appointment:", info.getValue());
                        }}
                >
                  {t('Chat')}
                </button>
            }
            { info.getValue().appointmentType === "TELECONSULTATION_VIDEO" &&
                <button className="px-2 py-1 text-white bg-green-500 rounded hover:bg-green-600"
                        onClick={() => {
                          console.log("Call appointment:", info.getValue());
                        }}
                >
                  {t('Call')}
                </button>
            }
            <button
                className="px-2 py-1 text-white bg-red-500 rounded hover:bg-red-600"
                onClick={() => {
                  console.log("Cancel appointment:", info.getValue());
                }}
            >
              {t('Cancel')}
            </button>
          </div>
      ),
    })
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...appointmentData]);
  React.useEffect(() => {
    setData([...appointmentData]);
  }, [appointmentData]);
  const [{pageIndex, pageSize}, setPagination] =
      React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 6,
      });

  const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize]
  );
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <div className="flex w-[400px] max-w-full items-center rounded-xl pt-[20px]">
          <div className="flex h-[38px] w-[400px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white">
            <SearchIcon />
            <input
                value={globalFilter ?? ""}
                onChange={(e: any) => setGlobalFilter(e.target.value)}
                type="text"
                placeholder={t("Search...")}
                className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
            />
          </div>
        </div>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => {
                    return (
                        <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className="cursor-pointer border-b border-gray-200 pt-4 pb-2 pr-4 text-start dark:border-white/30"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                    );
                  })}
                </tr>
            ))}
            </thead>
            <tbody>
            {table
                .getRowModel()
                .rows.slice(0, 7)
                .map((row) => {
                  return (
                      <tr
                          key={row.id}
                          className="dark:border-white/30"
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                              <td
                                  key={cell.id}
                                  className="min-w-[150px] py-3  pr-4"
                              >
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                              </td>
                          );
                        })}
                      </tr>
                  );
                })}
            </tbody>
          </table>
          {/* pagination */}
          <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
            {/* left side */}
            <div className="flex items-center gap-3">
              <p className="> Show rows per page text-sm text-gray-700">
                {t('Showing 6 rows per page')}
              </p>
            </div>
            {/* right side */}
            <div className="flex items-center gap-2">
              <button
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
              >
                <MdChevronLeft />
              </button>

              {createPages(table.getPageCount()).map((pageNumber, index) => {
                return (
                    <button
                        className={`linear flex h-10 w-10 items-center justify-center rounded-full p-2 text-sm transition duration-200 ${
                            pageNumber === pageIndex + 1
                                ? "bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                : "border-[1px] border-gray-400 bg-[transparent] dark:border-white dark:text-white"
                        }`}
                        onClick={() => table.setPageIndex(pageNumber - 1)}
                        key={index}
                    >
                      {pageNumber}
                    </button>
                );
              })}
              <button
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
              >
                <MdChevronRight />
              </button>
            </div>
          </div>
        </div>
      </Card>
  );
}

export default AppointmentTable;
const columnHelper = createColumnHelper<RowObj>();
