import {Pageable} from "../../../rest-client-sdk";

export interface Physician {
    physicianId: string;
    qualifications: string;
    specialization: string;
    description: string;
    educationRecord: string;
    previousExperience: string;
    profilePhotoUrl: string | null;
    firstName: string;
    lastName: string;
}

interface Page {
    size: number;
    number: number;
    totalElements: number;
    totalPages: number;
}

export interface ApiResponse {
    totalPages: number;
    totalElements: number;
    size: number;
    content: Physician[];
    page: Page;
}

export class PageableImpl implements Pageable {
    constructor(public page: number, public size: number) {}
}