import { MdDashboard, MdApps } from "react-icons/md";
import SearchIcon from "components/icons/SearchIcon";
import { useTranslation } from "react-i18next";
const Search = (props: {
  name: string, setName: React.Dispatch<React.SetStateAction<string>>,
  gender: string, setGender: React.Dispatch<React.SetStateAction<string>>,
  language: string, setLanguage: React.Dispatch<React.SetStateAction<string>>,
  specialization: string, setSpecialization: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { name, setName, gender, setGender, language, setLanguage, specialization, setSpecialization } = props;

  const { t } = useTranslation();
  return (
    <div className="mx-auto mt-5 flex w-full gap-1 overflow-hidden md:!gap-6 xl:w-[96%]">
      <div className="flex h-[45px] w-full rounded-xl border !border-gray-200 dark:!border-none dark:!bg-navy-700 md:w-[870px] xl:w-full">
        <SearchIcon />
        <input
          placeholder={t("Search...")}
          className="!w-full rounded-xl text-sm font-medium outline-none dark:bg-navy-700 dark:text-white sm:w-[50px] xl:!w-full"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="w-full rounded-xl border !border-gray-200 px-3 text-sm font-medium text-gray-600 outline-none dark:!border-none dark:bg-navy-700 sm:w-full md:w-fit">
        <select
          className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-600 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
          name=""
          id=""
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="">⚥ Any</option>
          <option value="FEMALE">♀ Female</option>
          <option value="MALE">♂ Male</option>
        </select>
      </div>
      <div
        className="w-full rounded-xl border !border-gray-200 px-3 text-sm font-medium text-gray-600 outline-none dark:!border-none dark:bg-navy-700 md:w-fit">
        <select
          className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-600 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
          name=""
          id=""
          value={specialization}
          onChange={e => setSpecialization(e.target.value)}
        >
          <option value="">Any</option>
          <option value="Cardiology">Cardiology</option>
          <option value="Aesthetics">Aesthetics</option>
        </select>
      </div>
      <div
        className="w-full rounded-xl border !border-gray-200 px-3 text-sm font-medium text-gray-600 outline-none dark:!border-none dark:bg-navy-700 md:w-fit">
        <select
          className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-600 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
          name=""
          id=""
          value={language}
          onChange={e => setLanguage(e.target.value)}
        >
          <option value="English">🌐 English Speaker</option>
          <option value="Turkish">🌐 Turkish Speaker</option>
        </select>
      </div>

      <button className="linear flex items-center justify-center rounded-xl border !border-gray-200 p-3 text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-50 dark:!border-none dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
        <MdDashboard />
      </button>
      <button className="linear flex items-center justify-center rounded-xl border !border-gray-200 p-3 text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-50 dark:!border-none dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
        <MdApps />
      </button>
    </div>
  );
};

export default Search;