import Card from "components/card";
import { useAuth } from "../../../context/AuthProvider";
import { AppointmentControllerApi, Configuration } from "../../../rest-client-sdk";
import { PageableImpl } from "../../patient/choosePhysician/interfaces";
import React, { useEffect, useState } from "react";
import { Appointment, AppointmentResponse } from "./interfaces";
import AppointmentTable from "./components/AppointmentTable";

const PhysicianAppointmentsOverview = () => {
    const auth = useAuth();
    const [appointments, setAppointments] = useState([]);

    const appointmentControllerApi = new AppointmentControllerApi(
        new Configuration({ basePath: process.env.REACT_APP_API_URL })
    );

    const mapAppointmentsData = (appointmentData: Appointment[]) =>
        appointmentData.map((appointment: Appointment) => ({
            appointmentActions: {
                id: appointment.appointmentId,
                appointmentType: appointment.type,
            },
            date: appointment.appointmentTime,
            patientProfile: {
                id: appointment.patientId,
                name: appointment.patientName,
                profilePhotoUrl: "https://i.ibb.co/zPxBHYv/241143773-8212166459343985239-7834018950652403662-n-1.jpg"//appointment.patientProfilePhotoUrl,
            },
            appointmentType: appointment.type,
            timeScheduled: appointment.appointmentTime,
            status: appointment.status,
        }));

    const fetchAppointments = async (token: string) => {
        const response = await appointmentControllerApi.listAppointments(new PageableImpl(0, 5), {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data as AppointmentResponse;
    };

    const getAppointmentsList = async () => {
        try {
            let responseData = await fetchAppointments(auth.token);

            if (responseData.statusCode === 200 && Array.isArray(responseData.data.content)) {
                setAppointments(mapAppointmentsData(responseData.data.content));
            } else if (responseData.statusCode === 401) {
                const newAccessToken = await auth.refreshTokenAction();
                responseData = await fetchAppointments(newAccessToken);

                if (responseData.statusCode === 200 && Array.isArray(responseData.data.content)) {
                    setAppointments(mapAppointmentsData(responseData.data.content));
                }
            } else {
                console.error("Unexpected response status:", responseData.statusCode);
            }
        } catch (error) {
            console.error("Error fetching appointments:", error);
        }
    };

    useEffect(() => {
        getAppointmentsList().then(() => console.log("Physician appointments fetched"));
    }, []);

    return (
        <Card extra="w-full h-full bg-white mt-3">
            <AppointmentTable appointmentData={appointments} />
        </Card>
    );
};

export default PhysicianAppointmentsOverview;
