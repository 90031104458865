import { IoMdStar, IoMdStarOutline } from "react-icons/io";

const Rating = (props: {
  stars: number;
  value: number | string;
  mb?: string;
}) => {
  const { stars, value, mb } = props;
  return (
    <div className={`flex items-center gap-3 ${mb} w-full`}>
      <div className="flex items-center text-l text-yellow-500">
        <p> {stars >= 1 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 2 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 3 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 4 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 5 ? <IoMdStar /> : <IoMdStarOutline />} </p>
      </div>
      <h1 className="text-base font-bold text-gray-600"> {value} </h1>
    </div>
  );
};

export default Rating;
